import React from "react"
import styled from "styled-components"

import referralLogo from "../imgs/referral.png"
import facebookLogo from "../imgs/facebook.png"
import instagramLogo from "../imgs/instagram.png"
import twitterLogo from "../imgs/twitter.png"

const MyDesktopReferralCardB = styled.div`
  width: 370px;
  z-index: 1;
  position: relative;
  margin-top: 62px;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: left;

  .referral-logo-div {
    width: 104px;
    height: 104px;
  }

  .referral-logo-img {
    width: 104px;
    height: 104px;
  }

  .referral-h3 {
    color: white;
    margin-top: 28px;
    text-align: left;
  }

  .fsize24 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: 0px;
  }

  .referral-share {
    background-color: white;
    border-radius: 12px;
    padding: 24px 20px 20px;
    margin: 41px 0 0 0;
    width: 100%;
  }

  .rs-line1 {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
  }

  .code {
    color: rgb(102, 122, 244);
    flex: 0.6;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .copy-code {
    flex: 0.2;
  }

  .ccbutton {
    padding: 11px 15px 10px;
    color: #667af4;
    background-color: white;
    border: 0;
    display: inline-block;
    font-size: 12px;
    text-align: center;
    line-height: 14px;
    border-radius: 32px;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: 0 0 0 1px hsla(216, 33.3%, 14.7%, 0.1),
      0 1px 3px 0 hsla(216, 12.4%, 39.4%, 0.06);
  }

  .rs-line2 {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }

  .social-logo {
    height: 20px;
    margin-right: 20px;
  }
  .share-h4 {
    color: white;
    margin-top: 17px;
    font-size: 20px;
    line-height: 24px;
  }
`

export default function DesktopReferralCardB() {
  return (
    <MyDesktopReferralCardB>
      <div className="referral-logo-div">
        <img
          className="referral-logo-img"
          src={referralLogo}
          alt="referral-logo-img"
        />
      </div>
      <h3 className="referral-h3 fsize24">Your referral code</h3>
      <div className="referral-share">
        <div className="rs-line1">
          <h3 className="code fsize24">ZXCVBNM</h3>
          <div className="copy-code">
            <span>
              <button className="ccbutton">COPY</button>
            </span>
          </div>
        </div>
        <div className="rs-line2">
          <img
            className="social-logo"
            src={facebookLogo}
            alt="facebook-logo"
          ></img>
          <img
            className="social-logo"
            src={instagramLogo}
            alt="instagram-logo"
          ></img>
          <img
            className="social-logo"
            src={twitterLogo}
            alt="twitter-logo"
          ></img>
        </div>
      </div>
      <h4 className="share-h4">Share it to save $10</h4>
    </MyDesktopReferralCardB>
  )
}
