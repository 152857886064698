import React from "react"
import styled from "styled-components"

const MyRootDiv = styled.div`
  // hide the desktop div on mobile devices
  @media screen and (max-width: 960px) {
    display: none;
  }

  opacity: 1;

  .placeholder-div {
    height: 61px;
  }
`

export default function DesktopRootDiv(props) {
  return <MyRootDiv>{props.children}</MyRootDiv>
}
