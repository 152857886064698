import React from "react"
import styled from "styled-components"

import DesktopReferralCardA from "./DesktopReferralCardA"
import DesktopReferralCardB from "./DesktopReferralCardB"

import DesktopNavbar from "./DesktopNavbar"
import DesktopRootDiv from "./DesktopRootDiv"

const MyDesktopReferralBody = styled.div`
  min-height: 348px;
  position: relative;

  .child1 {
    max-width: 930px;
    width: 90%;
    margin: 0 auto;
  }

  .c1-flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }

  .child2 {
    top: 0;
    left: 53%;
    width: 47%;
    bottom: 0;
    position: fixed;
    background: rgba(102, 122, 244, 0.9);
  }
`

export default function DesktopReferralBody({ location }) {
  return (
    <DesktopRootDiv>
      <div className="root-top">
        <DesktopNavbar location={location} />
      </div>
      <div className="placeholder-div" />
      <MyDesktopReferralBody>
        <div className="child1">
          <div className="c1-flex">
            <DesktopReferralCardA />
            <DesktopReferralCardB />
          </div>
        </div>
        <div className="child2" />
      </MyDesktopReferralBody>
    </DesktopRootDiv>
  )
}
