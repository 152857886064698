import React from "react"
import styled from "styled-components"

const MyDesktopReferralCardA = styled.div`
  max-width: 450px;

  .myh2 {
    font-size: 30px;
    line-height: 36px;
    font-weight: 700;
  }

  .myh3 {
    margin-top: 15px;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }

  .myh4 {
    margin: 31px 0 12px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2px;
  }

  .pstyle {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
  }

  .myp1 {
    margin: 0;
    padding: 0;
  }

  .tmargin23 {
    margin-top: 23px;
  }

  .tmargin48 {
    margin-top: 48px;
  }

  .numbers-div {
    border: 1px solid #e9ebed;
    margin-top: 18px;
    box-shadow: 0 1px 3px 0 rgba(88, 98, 113, 0.06);
    border-radius: 9px;
  }

  .flex-row {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
  }

  .nd1 {
    padding: 13px 12px;
  }

  .counter {
    background-color: rgba(102, 122, 244, 0.2);
    margin-right: 12px;
  }

  .size35 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    user-select: none;
    transition: background-color 0.25s cubic-bezier(0.8, 0.3, 0.45, 0.95),
      box-shadow 0.25s cubic-bezier(0.8, 0.3, 0.45, 0.95);
  }

  .counter-div {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
  }

  .counter-p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.3px;
    margin: 0;
    padding: 0;
  }

  .nd2 {
    border-top: 1px solid #e9ebed;
    padding: 13px 12px;
  }
`

export default function DesktopReferralCardA() {
  return (
    <MyDesktopReferralCardA>
      <h2 className="myh2">Refer friends to save!</h2>
      <h3 className="myh3">
        For every friend you introduce to US Mobile, we will give you both up to
        $10.
      </h3>
      <h4 className="myh4">Details</h4>
      <p className="myp1 pstyle">
        Your friends can use your code at activation to get 50% off up to $10 in
        savings on their first plan.
      </p>
      <p className="tmargin23 pstyle">
        You will then be eligible for 50% off up to $10 in savings on your next
        bill when using Autopay.
      </p>
      <p className="tmargin23 pstyle">Maximum # of discounted bills: 6</p>
      <div className="tmargin23">
        Codes may not be shared on coupon sharing sites. Codes{" "}
      </div>
      <div className="tmargin48">
        <h4>Your numbers</h4>
        <div className="numbers-div">
          <div className="nd1 flex-row">
            <div className="counter size35">
              <div className="counter-div">
                <p className="counter-p">0/6</p>
              </div>
            </div>
            <p className="pstyle">friends successfully referred</p>
          </div>
          <div className="nd1 flex-row">
            <div className="counter size35">
              <div className="counter-div">
                <p className="counter-p">0</p>
              </div>
            </div>
            <p className="pstyle">bills elligible for discounted payment</p>
          </div>
        </div>
      </div>
      <div></div>
    </MyDesktopReferralCardA>
  )
}
